import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';

import { VERIFY_REGISTER_TOKEN } from '../crud';

import { navigate } from '../utils/utils';

import Layout from '../components/layout';
import { SygnaLogoHeader } from '../components/common';
import blockSvg from '../images/block.svg';

const useStyles = makeStyles(() => ({
  content: {
    background: 'white',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  imageContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-113px',
    marginLeft: '-193px',
  },
  image: {
    width: '250px',
    height: '200px',
    marginLeft: '68px',
    marginRight: '68px',
  },
  link: {
    width: '100%',
    textAlign: 'center',
  },
}));

export default function Register({ location }) {
  const parsed = queryString.parse(location.search);
  const { token } = parsed;

  const [open, setOpen] = useState(false);

  const [verifyRegisterToken] = useMutation(VERIFY_REGISTER_TOKEN);

  const classes = useStyles();

  const verifyToken = async () => {
    try {
      await verifyRegisterToken({
        variables: {
          token,
        },
      });
      navigate('/registration', { state: { token }, replace: true });
    } catch (error) {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (!token) {
      setOpen(true);
      return;
    }
    verifyToken();
  }, []);

  if (open) {
    return (
      <Layout maxWidth="100%">
        <div className={classes.content}>
          <SygnaLogoHeader />
        </div>
        <div id="main">
          <div className={classes.imageContainer}>
            <img className={classes.image} src={blockSvg} alt="The link is expired" />
            <h6 className={classes.link}>
              The link is expired. Please contact&nbsp;
              <a href="mailto:services@sygna.io">services@sygna.io</a>
            </h6>
          </div>
        </div>
      </Layout>
    );
  }
  return null;
}

Register.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};
